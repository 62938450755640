import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import GoserviceView from "../views/GoserviceView.vue";
import EtiketView from "../views/EtiketView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/goservice",
    name: "Goservice",
    component: GoserviceView,
  },
  {
    path: "/etiket",
    name: "Etiket",
    component: EtiketView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
