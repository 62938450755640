<template>
  <div class="home">
    <banner-component />
    <button-component />
    <div class="card_track">
      <p class="title-track">Tracking Service dan Garansi</p>
      <p class="desc-track">Masukkan nomor invoice</p>
      <div class="form_track">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            aria-label="Username"
            aria-describedby="basic-addon1"
            v-model="invoiceNumber"
            @keydown.enter="searchInvoice"
            placeholder="INV-XXX"
          />
          <span class="input-group-text" id="basic-addon1">
            <font-awesome-icon icon="magnifying-glass" />
          </span>
        </div>
        <div class="button-search">
          <button class="btn btn-primary" type="submit" @click="searchInvoice">
            CARI
          </button>
        </div>
      </div>
    </div>
    <div class="illustration" v-if="illustration">
      <img src="../assets/img/illustration1.png" alt="" />
    </div>
    <div v-if="input">
      <div
        class="alert mt-1 alert-warning d-flex align-items-center mt-2"
        role="alert"
      >
        <font-awesome-icon icon="fa-solid fa-triangle-exclamation " size="lg" />
        <div>
          <span class="text mx-2"> Masukkan No Invoice</span>
        </div>
      </div>
    </div>
    <section class="tracking-order mb-5">
      <div>
        <div v-if="showResults">
          <article class="card card-bg">
            <header class="card-header">
              No Pesanan: {{ invoice.invoice_serv }}
            </header>
            <div class="card-body">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md col-6">
                      <p class="title-track">Nama:</p>
                      <p class="desc-track" v-if="invoice.name == null">
                        {{ invoice.nm_konsumen }}
                      </p>
                      <p class="desc-track" v-else>{{ invoice.name }}</p>
                    </div>
                    <div class="col-md col-6">
                      <p class="title-track">Model:</p>
                      <p class="desc-track">{{ invoice.merek_hp }}</p>
                    </div>
                    <div class="col-md col-6">
                      <p class="title-track">Imei:</p>
                      <p class="desc-track">
                        {{ invoice.imei_1 }} / {{ invoice.imei_2 }}
                      </p>
                    </div>
                    <div class="col-md col-6">
                      <p class="title-track">Kerusakan:</p>
                      <p class="desc-track">{{ invoice.kerusakan }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="track">
                <div class="step" :class="{ active: invoice.id_proses >= 1 }">
                  <span class="icon">
                    <font-awesome-icon icon="fa-solid fa-list-ol" />
                  </span>
                  <span class="text">Antri</span>
                </div>
                <div class="step" :class="{ active: invoice.id_proses >= 2 }">
                  <span class="icon">
                    <font-awesome-icon icon="fa-solid fa-arrows-spin"
                  /></span>
                  <span class="text"> Proses</span>
                </div>
                <div class="step" v-if="invoice.id_proses <= 2">
                  <span class="icon">
                    <font-awesome-icon icon="fa-solid fa-circle-check" />
                  </span>
                  <span class="text">Selesai</span>
                </div>
                <div
                  class="step"
                  v-if="invoice.id_proses >= 3"
                  :class="{ active: invoice.id_proses >= 3 }"
                >
                  <template
                    v-if="
                      invoice.id_proses == 4 ||
                      (invoice.id_proses >= 5 && invoice.catatan)
                    "
                  >
                    <span class="icon">
                      <font-awesome-icon icon="fa-solid fa-times-circle" />
                    </span>
                    <span class="text"> Cancel </span>
                  </template>
                  <template
                    v-else-if="
                      invoice.id_proses == 3 ||
                      (invoice.id_proses >= 5 && !invoice.catatan)
                    "
                  >
                    <span class="icon">
                      <font-awesome-icon icon="fa-regular fa-circle-check" />
                    </span>
                    <span class="text"> Selesai </span>
                  </template>
                </div>
                <div class="step" :class="{ active: invoice.id_proses >= 5 }">
                  <span class="icon"
                    ><font-awesome-icon
                      icon="fa-solid fa-arrow-up-right-from-square"
                  /></span>
                  <span class="text">Diambil</span>
                </div>
              </div>
            </div>
          </article>

          <div v-if="invoice.id_proses == 2">
            <div
              v-if="invoice.notes != null"
              class="alert mt-1 alert-info d-flex align-items-center mt-2"
              role="alert"
            >
              <font-awesome-icon
                :icon="['fas', 'circle-info']"
                fade
                size="lg"
              />
              <div>
                <span class="text mx-2"> {{ invoice.notes }}</span>
              </div>
            </div>
          </div>
          <div
            v-if="
              invoice.id_proses == 4 ||
              (invoice.id_proses >= 5 && invoice.catatan)
            "
          >
            <div
              class="alert mt-1 alert-danger d-flex align-items-center mt-2"
              role="alert"
            >
              <font-awesome-icon
                :icon="['fas', 'circle-info']"
                fade
                size="lg"
              />
              <div>
                <span class="text mx-2"> {{ invoice.catatan }}</span>
              </div>
            </div>
          </div>
          <article class="card card-bg" v-if="invoice.id_proses >= 5">
            <header class="card-header">Status Garansi:</header>
            <div class="card-body">
              <span v-if="garansi !== null"
                ><p class="fw-bold">Masa Berlaku Hingga</p>
                {{ garansi.tgl_akhir }}</span
              >
              <span v-else>TANPA GARANSI</span>
            </div>
          </article>
        </div>
        <article class="card card-bg" v-if="garansiPart">
          <header class="card-header">Status Garansi:</header>
          <div class="card-body">
            <span v-if="garansiPart !== null"
              ><p class="fw-bold">Masa Berlaku Hingga</p>
              {{ garansiPart.tgl_akhir }}</span
            >
            <span v-else>TANPA GARANSI</span>
          </div>
        </article>
        <div v-if="not">
          <div
            class="alert mt-3 alert-danger d-flex align-items-center mt-2"
            role="alert"
          >
            <font-awesome-icon
              icon="fa-solid fa-triangle-exclamation "
              size="lg"
            />
            <div>
              <span class="text mx-2"> {{ not }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BannerComponent from "@/components/BannerComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import api from "@/api";
// @ is an alias to /src

export default {
  components: { BannerComponent, ButtonComponent },
  name: "HomeView",
  data() {
    return {
      illustration: true,
      showResults: false,
      // loading: false,
      input: false,
      invoiceNumber: "",
      not: "",
      name: "",
      garansiPart: "",
    };
  },
  mounted() {
    const params = new URLSearchParams(this.$route.query);
    const noInvoice = params.get("no_invoice");
    if (noInvoice) {
      this.invoiceNumber = noInvoice;
      this.searchInvoice();
    }
  },
  watch: {
    invoiceNumber(newValue) {
      if (!newValue) {
        this.clearResults();
      }
    },
  },

  methods: {
    async searchInvoice() {
      if (!this.invoiceNumber) {
        this.input = true;
        this.illustration = false;
        setTimeout(() => {
          this.input = false;
          this.illustration = true;
        }, 3000);
        return;
      }
      // this.loading = true;
      try {
        const response = await api.get(
          `Content/invoice?invoice=${this.invoiceNumber}`
        );
        if (response.data["status"] == 200) {
          if (response.data["data"][0] !== null) {
            this.showResults = true;
            this.invoice = response.data["data"][0];
            this.garansi = response.data["data"][1];
          }
          if (response.data["data"][0] == null) {
            this.garansiPart = response.data["data"][1];
          }
          this.not = "";
          this.illustration = false;
          // console.log(response.data["data"]);
        } else {
          this.not = response.data["message"];
          this.showResults = false;
          this.garansiPart = false;
          this.invoice = "";
          this.illustration = false;
        }
      } catch (error) {
        // console.error(error);
      }
    },
    clearResults() {
      setTimeout(() => {
        this.showResults = false;
        this.not = false;
        this.garansiPart = false;
        this.illustration = true;
      }, 2000);
    },
  },
};
</script>
