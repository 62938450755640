<template>
  <div class="home">
    <banner-component />
    <button-component />
    <div class="card_track">
      <p class="title-track">Cek Status Go Service</p>
      <p class="desc-track">Masukkan nomor E-tiket</p>
      <div class="form_track">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            aria-label="Username"
            v-model="searchTerm"
            @keydown.enter="searchData"
            placeholder="E_XXX"
            aria-describedby="basic-addon1"
          />
          <span class="input-group-text" id="basic-addon1">
            <font-awesome-icon icon="magnifying-glass" />
          </span>
        </div>
        <div class="button-search">
          <button @click="searchData" class="btn btn-primary">CARI</button>
        </div>
      </div>
    </div>
    <div class="illustration" v-if="illustration">
      <img src="../assets/img/illustration2.png" alt="" />
    </div>
    <div v-if="input">
      <div
        class="alert mt-1 alert-warning d-flex align-items-center mt-2"
        role="alert"
      >
        <font-awesome-icon icon="fa-solid fa-triangle-exclamation " size="lg" />
        <div>
          <span class="text mx-2"> Masukkan No E-Tiket Anda</span>
        </div>
      </div>
    </div>
    <div class="order-track">
      <div v-if="showResults">
        <div class="tracking-order">
          <article class="card card-bg">
            <header class="card-header">No E-Tiket: {{ item.e_tiket }}</header>
            <div class="card-body mb-3">
              <div class="track">
                <div class="step" :class="{ active: item.status >= 1 }">
                  <span class="icon"
                    ><font-awesome-icon icon="fa-regular fa-rectangle-list" />
                  </span>
                  <span class="text">Di Pesan</span>
                  <p class="text-date">{{ item.date_created }}</p>
                </div>
                <div class="step" :class="{ active: item.status >= 2 }">
                  <span class="icon">
                    <font-awesome-icon icon="fa-regular fa-eye" />
                  </span>
                  <span class="text"> Di Tinjau</span>
                </div>
                <div class="step" :class="{ active: item.status >= 3 }">
                  <template
                    v-if="
                      item.status == 4 || (item.status == 4 && item.catatan)
                    "
                  >
                    <span class="icon">
                      <font-awesome-icon icon="fa-solid fa-times-circle" />
                    </span>
                    <span class="text"> Cancel </span>
                    <!-- <p class="text-date">{{ item.catatan }}</p> -->
                  </template>
                  <template
                    v-else-if="
                      item.status <= 3 || (item.status == 3 && !item.catatan)
                    "
                  >
                    <span class="icon">
                      <font-awesome-icon icon="fa-solid fa-truck" />
                    </span>
                    <span class="text"> Di Terima </span>
                  </template>
                </div>
              </div>
            </div>
          </article>
          <div v-if="item.status == 3">
            <div
              class="alert mt-1 alert-primary d-flex align-items-center"
              role="alert"
              style="font-size: 14px"
            >
              <font-awesome-icon
                icon="fa-solid fa-triangle-exclamation "
                size="lg"
                style="margin-right: 10px"
              />
              <div>
                <span class="text"
                  >Untuk proses penjemputan barang, Nanti akan dihubungi oleh
                  admin</span
                >
              </div>
            </div>
          </div>
          <div v-if="item.status == 4 && item.catatan">
            <div
              class="alert mt-1 alert-danger d-flex align-items-center"
              role="alert"
            >
              <font-awesome-icon
                icon="fa-solid fa-triangle-exclamation "
                size="lg"
                style="margin-right: 10px"
              />
              <div>
                <span class="text">{{ item.catatan }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="not">
        <div
          class="alert mt-3 alert-danger d-flex align-items-center"
          role="alert"
        >
          <font-awesome-icon
            icon="fa-solid fa-triangle-exclamation "
            size="lg"
            style="margin-right: 10px"
          />
          <div>
            <span class="text">Data tidak ditemukan</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerComponent from "@/components/BannerComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import api from "@/api";
// @ is an alias to /src

export default {
  components: { BannerComponent, ButtonComponent },
  name: "HomeView",
  data() {
    return {
      illustration: true,
      searchTerm: "",
      showResults: false,
      input: false,
      not: false,
    };
  },

  watch: {
    searchTerm(newValue) {
      if (!newValue) {
        this.clearResults();
      }
    },
  },
  methods: {
    async searchData() {
      if (!this.searchTerm) {
        this.input = true;
        this.illustration = false;
        setTimeout(() => {
          this.input = false;
          this.illustration = true;
        }, 3000);
        return;
      }
      try {
        const response = await api.get(
          `Content/checkGoService/?etiket=${this.searchTerm}`
        );
        if (response.data["status"] == 200) {
          this.item = response.data["data"];
          this.showResults = true;
          this.not = false;
          this.illustration = false;
        } else {
          this.not = true;
          this.showResults = false;
          this.item = "";
          this.illustration = false;
        }
      } catch (error) {
        // console.error(error);
      }
    },
    clearResults() {
      setTimeout(() => {
        this.showResults = false;
        this.not = false;
        this.illustration = true;
      }, 2000);
    },
  },
};
</script>
<style>
.alert-warning {
  --bs-alert-color: #fff;
  --bs-alert-bg: #f76707;
  --bs-alert-border-color: #f76707;
  font-size: 14px;
}
.alert-danger {
  --bs-alert-color: #fff;
  --bs-alert-bg: #d63939;
  --bs-alert-border-color: #d63939;
  font-size: 14px;
}
.alert-primary {
  --bs-alert-color: #fff;
  --bs-alert-bg: #206bc4;
  --bs-alert-border-color: #206bc4;
  font-size: 14px;
}
</style>